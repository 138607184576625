<template>
  <div>
    <h2 class="title">
      Année Scolaire {{ anneeScolaire.libelle }}
    </h2>

    <v-card class="pa-5 mb-5">
      <div
        v-if="adhesion"
        class="mb-5"
      >
        <h2 class="title">
          Adhésions
        </h2>
        <v-row v-if="parts.departemental.niveau">
          <v-col cols="12">
            Part nationale:
            {{ parts.national.montant | currency }}
            <span v-if="parts.national.moyen_paiement">par {{ getMoyenPaiementById(parts.national.moyen_paiement).libelle }}</span>
          </v-col>
          <v-col cols="12">
            Part départementale:
            {{ parts.departemental.montant | currency }}
            <span v-if="parts.departemental.moyen_paiement">par {{ getMoyenPaiementById(parts.departemental.moyen_paiement).libelle }}</span>
            au niveau {{ parts.departemental.niveau.libelle }}
          </v-col>
          <v-col cols="12">
            <ListeParts
              :has-actions="false"
              :adhesion="adhesion"
            />
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12">
            Le parent n'est pas adhérent sur {{ anneeScolaire.libelle }}
          </v-col>
        </v-row>
      </div>

      <div
        v-if="abonnement"
        class="mb-5"
      >
        <h2 class="title">
          Abonnements
        </h2>
        <v-row
          v-if="abonnement"
        >
          <v-col
            cols="12"
            lg="4"
          >
            <h4>{{ abonnement.type_abonnement.revue.titre }}</h4>
            <h4>{{ abonnement.annee_scolaire.libelle }}</h4>
            <h4>
              {{ getTypeAbonnementLabel(abonnement) }}
            </h4>
            <p>{{ getAbonnementStatusLabel(abonnement) }}</p>
          </v-col>
          <v-col
            cols="12"
            lg="4"
          >
            <p>Montant: <span>{{ abonnement.montant | currency }}</span></p>
            <p>Date: <span>{{ abonnement.date | formatDate }}</span></p>
            <p>Valable jusqu'au: <span>{{ abonnement.date_fin | formatDate }}</span></p>
            <p>Email: <span>{{ abonnement.email }}</span></p>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12">
            Le parent n'est pas abonné sur {{ anneeScolaire.libelle }}
          </v-col>
        </v-row>
      </div>

      <div
        class="mb-5"
      >
        <h3 class="title">
          Dons
        </h3>
        <ListeDons
          :has-actions="false"
          :dons="dons"
        />
      </div>

      <div class="mb-5">
        <h3 class="title">
          Montant total payé
        </h3>
        <v-col cols="12">
          Total adhésion + abonnement + dons = {{ (sommeMontantAdhesions + sommeMontantDons + sommeMontantAbonnement) | currency }}
        </v-col>
      </div>
    </v-card>
  </div>
</template>
<script>
import ListeParts from './adhesion/part/Liste';
import ListeDons from './don/Liste';
import { getMoyenPaiementById } from '../../config/moyenPaiement';
import TYPES_ABONNEMENT from '../../config/typeAbonnementSimilaire';
import droitsMixin from './droits.mixin';

export default {
  name: 'ParentInformationAnneeScolaire',
  components: {
    ListeParts,
    ListeDons,
  },
  mixins: [
    droitsMixin,
  ],
  props: {
    anneeScolaire: {
      type: Object,
      default() {
        return {};
      },
    },
    adhesion: {
      type: Object,
      default() {
        return null;
      },
    },
    abonnement: {
      type: Object,
      default() {
        return null;
      },
    },
    dons: {
      type: Array,
      default() {
        return [];
      },
    },
    recapFinancier: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data: () => ({
  }),

  computed: {
    parts() {
      return this.adhesion && this.adhesion.parts;
    },
    sommeMontantDons() {
      let recapAbonnements = this.recapFinancier.abonnement;
      let sum = 0;
      recapAbonnements.reduce(function(total, obj)
      { sum = total + obj.montant; }, 0);
      return sum;
    },
    sommeMontantAdhesions() {
      let recapAdhesions = this.recapFinancier.adhesion;
      return recapAdhesions.national + recapAdhesions.departemental + recapAdhesions.local;
    },
    sommeMontantAbonnement() {
      let dons = this.recapFinancier.don;
      return dons.departemental + dons.local;
    },

  },
  methods: {
    getMoyenPaiementById,
    getTypeAbonnementLabel(abonnement) {
      if (abonnement.type_abonnement.type_abonnement_similaire_id === TYPES_ABONNEMENT.AVEC_ADHESION) {
        return 'Abonnement avec adhésion';
      }
      if (abonnement.type_abonnement.type_abonnement_similaire_id === TYPES_ABONNEMENT.SANS_ADHESION) {
        return 'Abonnement sans adhésion';
      }

      return '';
    },
    getAbonnementStatusLabel(abonnement) {
      if (abonnement.actif) {
        return '';
      }

      if (abonnement.annee_scolaire.is_courante) {
        return 'En cours de validation';
      } else {
        return 'Suspendu';
      }
    },
  },
};
</script>
