<template>
  <v-card>
    <div class="pa-5">
      <div class="mb-5">
        <v-btn
          v-if="canEdit(parent)"
          color="primary"
          :to="{ name: 'edition-parent', params: { parentId: parent.id }}"
          v-text="'Modifier'"
        />
        <v-btn
          v-if="canViewTableauBordParent"
          class="ml-2"
          :to="{ name: 'tableau-bord-parent', params: { parentId: parent.id }}"
          v-text="'Tableau de bord'"
        />
        <v-btn
          class="ml-2"
          @click="showHistorique"
        >
          Voir Historique
        </v-btn>
      </div>

      <div class="mb-5">
        <v-row>
          <v-col>
            <h2 class="title">
              Informations générales
            </h2>
          </v-col>
          <v-col
            align="right"
            class="mr-5"
          >
            <v-badge
              inline
              left
              :color="statusColor"
            >
              {{ parent.statut.libelle }}
            </v-badge>
          </v-col>
        </v-row>
        <v-card class="pa-5 mb-5">
          <v-row justify="center">
            <v-col
              cols="12"
              md="3"
            >
              Code :
              {{ parent.code }}
            </v-col>
            <v-col
              cols="12"
              md="9"
            >
              Conseil local :
              {{ parent.conseil_local.nom }}
              {{ parent.conseil_local.commune.code_postal }}
              {{ parent.conseil_local.commune.ville }}
              <br>
              Code CL: {{ parent.conseil_local.code }}
            </v-col>
          </v-row>
          <br>
          <v-row justify="center">
            <v-col
              cols="12"
              md="3"
            >
              Nom :
              {{ parent.personne.nom }}
              <br>
              Prénom :
              {{ parent.personne.prenom }}
              <br>
              Email :
              {{ parent.contact.email }}
              <br>
              Téléphone fixe :
              {{ parent.contact.telephone_fixe }}
              <br>
              Téléphone mobile :
              {{ parent.contact.telephone_mobile }}
            </v-col>
            <v-col
              cols="12"
              md="9"
            >
              Adresse :<br>
              {{ parent.adresse.ligne1 }}<br>
              <span v-if="parent.adresse.ligne2">
                {{ parent.adresse.ligne2 }}<br>
              </span>
              {{ parent.adresse.commune.code_postal }}<br>
              {{ parent.adresse.commune.ville }}
            </v-col>
          </v-row>
          <br>
          <v-row justify="center">
            <v-col cols="12">
              Abonné à la newsletter :
              {{ parent.contact.abonne_newsletter ? 'Oui': 'Non' }}
            </v-col>
            <v-col cols="12">
              Commentaire :
              {{ parent.commentaire }}
            </v-col>
          </v-row>
        </v-card>
      </div>

      <div class="mb-5">
        <ListeEnfants
          :enfants="enfants"
          :has-actions="false"
        />
      </div>

      <div
        v-for="anneeScolaire in listAnneeScolaireSaisie"
        :key="anneeScolaire.id"
      >
        <ParentInformationAnneeScolaire 
          :annee-scolaire="anneeScolaire" 
          :adhesion="getAdhesion(anneeScolaire.id)"
          :abonnement="getAbonnement(anneeScolaire.id)"
          :dons="getDons(anneeScolaire.id)"
          :recap-financier="getRecapFinancier(anneeScolaire.id)"
        />
      </div>
      
      <div class="mb-5">
        <h2 class="title">
          Responsabilités
        </h2>
        <v-card class="pa-5 mb-5">
          <v-row>
            <v-col cols="12">
              Départementale:
              <span v-if="responsabilites.departemental.length === 0">Aucune</span>
              <span
                v-for="responsabilite in responsabilites.departemental"
                :key="responsabilite.id"
              >{{ responsabilite.nom }} </span>
            </v-col>
            <v-col cols="12">
              Locale conseil principal :
              <span v-if="responsabilites.local.length === 0">Aucune</span>
              <span
                v-for="responsabilite in responsabilites.local"
                :key="responsabilite.id"
              >{{ responsabilite.nom }} </span>
            </v-col>
            <v-col
              v-if="responsabilites.parAppartenance.length > 0"
              cols="12"
            >
              Locales par appartenance:
              <span v-if="responsabilites.parAppartenance.length === 0">Aucune</span>
              <span
                v-for="responsabilite in responsabilites.parAppartenance"
                :key="responsabilite.id"
              >{{ responsabilite.nom }} ({{ responsabilite.conseilLocal.nom }}) </span>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
    <Historique
      :parent="parent"
      :should-show="showHistoricSheet"
      @close="showHistoricSheet = !showHistoricSheet"
    />
  </v-card>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import ParentInformationAnneeScolaire from './InformationAnneeScolaire';
import ListeEnfants from './enfant/Liste';
import Historique from './Historique';
import STATUTS_PARENT, { colorParent } from '../../config/statutParent';
import droitsMixin from './droits.mixin';

export default {
  name: 'ParentInformation',

  components: {
    ListeEnfants,
    ParentInformationAnneeScolaire,
    Historique,
  },
  mixins: [
    droitsMixin,
  ],
  data: () => ({
    showHistoricSheet: false,
  }),

  computed: {
    ...mapState({
      parent: state => state.parent,
      enfants: state => state.enfants,
      abonnements: state => state.abonnement.parentAbonnements,
    }),
    ...mapState('adhesion', [
       'adhesions',
    ]),
    ...mapState('don', [
       'dons',
    ]),
    ...mapState('recapFinancier', [
      'recapFinanciers',
    ]),
    ...mapGetters('parametrages/anneeScolaire', {
      listAnneeScolaireSaisie: 'listAnneeScolaireSaisie',
    }),
    responsabilites() {
      return this.$store.getters['responsabilites/getResponsabilitesParent'];
    },
    statusColor() {
      let txtColor = colorParent(this.parent);
      if (this.parent.statut.id == STATUTS_PARENT.INACTIF && this.isAdherentNPlus1()) {
        txtColor = 'purple';
      }
      return txtColor;
    },
  },
  methods: {
    showHistorique(){
      this.showHistoricSheet = true;
    },
    getAdhesion(anneeScolaireId) {
      return this.adhesions.find(adh => adh.annee_scolaire_id == anneeScolaireId);
    },
    getAbonnement(anneeScolaireId) {
      return this.abonnements.find(abo => abo.annee_scolaire.id == anneeScolaireId);
    },
    getDons(anneeScolaireId) {
      return this.dons.filter(don => don.annee_scolaire_id == anneeScolaireId);
    },
    getRecapFinancier(anneeScolaireId) {
      const recap = this.recapFinanciers.find(recap => recap.annee_scolaire_id == anneeScolaireId);
      if (recap) {
        return recap;
      }
      return { adhesion: { national:0, departemental:0, local:0 }, abonnement: [], don: [], donLocal:[] };
    },
    isAdherentNPlus1() {
      const anneeNPlus1 = this.listAnneeScolaireSaisie.find(a => !a.courante);
      if (!anneeNPlus1) {
        return false;
      }
      return this.getAdhesion(anneeNPlus1.id);
    },
  },
};
</script>
