<template>
  <div>
    <ParentInformation />
  </div>
</template>
<script>
import ParentInformation from '../../components/parent/Information';
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
export default {
  name: 'ParentInformationContainer',

  components: {
    ParentInformation,
  },
  data: () => ({
    parent: null,
  }),

  computed: {
    ...mapState({
      enfants: state => state.enfants,
    }),
    ...mapGetters('parametrages/anneeScolaire', {
      listAnneeScolaireSaisie: 'listAnneeScolaireSaisie',
      getAnneeScolaireCourante: 'getAnneeScolaireCourante',
    }),
  },

  created() {
    let parentId = this.$route.params.parentId;
    this.loadFilterAnneesScolaires().then(() => {
      this.setStatesAnneeScolaireId(this.getAnneeScolaireCourante.id);
    });
    this.loadParentInfosById({
      parentId,
    })
        .then(parent => {
          this.loadEnfantsByParentId({ parentId })
            .then(() => {
              this.loadResponsabilitesLocalParAppartenance();
            });
          this.loadAllFinancialDatasByParentId({ parentId });
          this.loadNiveauxPartDepart(parent.conseil_local.id);
          this.loadResponsabilitesLocal(parent.conseil_local.id);
          if(!this.isUserLocal) { // cdpe or nat
            this.loadResponsabilitesDepartemental(parent.conseil_local.cdpe_id);
          }
        })
    ;
  },

  methods: {
    ...mapActions([
      'loadEnfantsByParentId',
      'loadParentInfosById',
      'loadAllFinancialDatasByParentId',
      'resetState',
    ]),
    ...mapActions('adhesion', [
      'loadNiveauxPartDepart',
    ]),
    ...mapActions('responsabilites', {
      loadResponsabilitesLocal: 'loadResponsabilitesLocal',
      loadResponsabilitesLocalParAppartenance: 'loadResponsabilitesLocalParAppartenance',
      loadResponsabilitesDepartemental: 'loadResponsabilitesDepartemental',
    }),
    ...mapMutations('recapFinancier', {
      setRecapFinancierAnneeScolaireId: 'SET_ANNEE_SCOLAIRE_ID',
    }),
    ...mapMutations('adhesion', {
      setAdhesionAnneeScolaireId: 'SET_ANNEE_SCOLAIRE_ID',
    }),
    ...mapMutations('abonnement', {
      setAbonnementAnneeScolaireId: 'SET_ANNEE_SCOLAIRE_ID',
    }),
    ...mapMutations('don', {
      setDonAnneeScolaireId: 'SET_ANNEE_SCOLAIRE_ID',
    }),
    ...mapActions('parametrages/anneeScolaire', {
        loadFilterAnneesScolaires: 'loadFilterAnneesScolaires',
    }),

    setStatesAnneeScolaireId(anneeId) {
      this.setRecapFinancierAnneeScolaireId(anneeId);
      this.setAdhesionAnneeScolaireId(anneeId);
      this.setAbonnementAnneeScolaireId(anneeId);
      this.setDonAnneeScolaireId(anneeId);
    },
  },
};
</script>
